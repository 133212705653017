import Inputmask from "inputmask"

Inputmask.extendAliases({
  date: {
    alias: 'datetime',
    inputFormat: 'dd/mm/yyyy',
    greedy: false,
    placeholder: '_',
    clearIncomplete: true
  }
})

Inputmask().mask(document.querySelectorAll("input[data-inputmask]"))
